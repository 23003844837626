import { initializeApp } from 'firebase/app';
import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABLEG3PtYJqbTywp2Uj3G9tSBYoRoORMQ",
  authDomain: "troopsong-1a530.firebaseapp.com",
  projectId: "troopsong-1a530",
  storageBucket: "troopsong-1a530.appspot.com",
  messagingSenderId: "435601604200",
  appId: "1:435601604200:web:243c69763c7f1abe3405dd",
  measurementId: "G-DWN19T2B55"
  };

firebase.initializeApp(firebaseConfig)

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export const authentication = getAuth(app);

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyABLEG3PtYJqbTywp2Uj3G9tSBYoRoORMQ",
//   authDomain: "troopsong-1a530.firebaseapp.com",
//   projectId: "troopsong-1a530",
//   storageBucket: "troopsong-1a530.appspot.com",
//   messagingSenderId: "435601604200",
//   appId: "1:435601604200:web:243c69763c7f1abe3405dd",
//   measurementId: "G-DWN19T2B55"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);