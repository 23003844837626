import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
// import IconButton from 'react-bootstrap/InputGroup';
// import { ListGroup, ListGroupItem } from 'react-bootstrap';
// import { getByDisplayValue } from '@testing-library/react';
// import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { Container, Row, Col } from 'react-bootstrap';
// import useSound from 'use-sound'
// import ReactHowler from 'react-howler'
import {Howl} from "howler"
import {ReactHowler} from 'react-howler'
import { useAudioPlayer } from "react-use-audio-player"
import { AudioPlayer } from './audioplayer';
import mixpanel from 'mixpanel-browser';


var syl_length
var mySound
var easy_num
var difficulty
var diffi
var songNumber
var words_num
let lines_num
export var lines
export var inputF
// let n = 0
let sound
// var sound = (song) =>
//   new Howl({
//   src:song,
//   html5:true,
// })



const Song = ({songs, original}) => {
    // var sound
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [playing, setPlaying] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    mixpanel.init("05cba2b0a08ef860de0629b3b1a3e5bf", {
    });


    function soundPlay (src, lyrics) {
      mixpanel.track('next button', {
        time: new Date().toLocaleString()
      })
      // console.log('sound: ', sound)
      lines = (lyrics.match(/[*\n]/g) || []).length
      // inputF = lyrics.split(/[*\n]/).map(word => ({word}))
      // if src==u
      sound = new Howl({
        src:src,
        html5:true,
      })

      // console.log('sound2: ', sound)
      sound.play();
      
    }


    const handleShow = (song) => {
      mySound = song.songLink;
      var lyric = song.lyrics;
      var lines_array = lyric.split(/[*\n]/);
      lines_num = lines_array.length
      var words_array = lyric.split(' ')
      words_num = words_array.length
      // sound = 
      // var diffi = difficulty
      // console.log('lines_num')
      // console.log(lines_num)
      // console.log('words_num')
      // console.log(words_num)

      if ((song.number / song.lyrics.split(' ').length) <= 0.25) {
        diffi = 'Hard 🥇'
      } else if (0.26 <= (song.number / song.lyrics.split(' ').length) <= 0.50) {
        diffi = 'Medium 🥈'
      } else if (0.51 <= (song.number / song.lyrics.split(' ').length) <= 0.70) {
        diffi = 'Easy 🥉'
      }else {
        diffi = 'Very Easy 🪙'
      }

      setInputField(song.lyrics.split(/[*\n]/).map(word => ({word})))
      setShow(true)
    };



    const [inputFields, setInputField] = useState([])

    function convertToSyllables(wordToBreak){
      // word_list = breakSentenceIntoWords()
      wordToBreak = wordToBreak.toLowerCase();                                     
      wordToBreak = wordToBreak.replace(/(?:[^laeiouy]|ed|[^laeiouy]e)$/, '');   
      wordToBreak = wordToBreak.replace(/^y/, '');                                  
      var syl = wordToBreak.match(/[aeiouy]{1,2}/g);
      // console.log("syl: "+ syl);
      if (syl == null){
          syl_length = 1
        }else{
          syl_length = syl.length
        }
      // for (let i = 0; i < word_list.length; i++){
      //   word = word_list[i].toLowerCase();                                     
      //   word = word.replace(/(?:[^laeiouy]|ed|[^laeiouy]e)$/, '');   
      //   word = word.replace(/^y/, '');                                  
      //   var syl = word.match(/[aeiouy]{1,2}/g);
      //   console.log("here: "+ syl);
      //   if(i==0)
      //   {
      //     // create space between the second sign and the last comma like in db
      //   sentenceSyllableSignature.push(syl.length)
      //   console.log(syl)
      //   }else{
      //     sentenceSyllableSignature.push(' '+syl.length)
      //   console.log(syl)
      //   }
      // }
      return syl_length;
    }



    return (
    
        <ul class="list-group collapse show wrapper" style={{ marginLeft: 10, marginRight: 10}}>
        {songs.map((song, id) => (
            <button type="button" class="list-group-item list-group-item-action" 
            
            onClick={()=>{
              try {
                        if (isPlaying) {
                         
                            // console.log('is playing')
                            // console.log('sounder :', sound)
                            sound.stop();
                            // html5().pause();
                            setIsPlaying(false);
                          } else {
                      
                            // console.log('start playing')
                            // console.log('sounder2 :', sound)
                            setIsPlaying(true);
                            soundPlay(song.songLink, song.lyrics)
                            // sound(song.songLink).play();
                          }

                  } catch (error) {
                    // handle the error here
                      setIsPlaying(false);
                     alert('sorry, no preview sound for this song yet')
                     return
                  }
               
              
              }}
            
            >
            {/* <div><b class="mb-1"> Success: </b> <small class="text-muted"> {song.number} </small></div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> {song.artistName} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> ({song.songTitle}) </small></div>
            {/* <div><b class="mb-1">  </b> <small style={{"color": "#EF3503"}}> ♫ {song.lyrics.substring(0, song.number)} ♫  </small> <small class="text-muted"> {song.lyrics.substring(song.number)} ♫ </small></div> */}
            {/* <div><b class="mb-1">  </b> <small style={{"color": "#EF3503"}}> ♫ {song.lyrics.split(' ', song.number)} </small> <small class="text-muted"> {song.lyrics.substring(song.number)} ♫ </small></div> */}
            {/* <div><b class="mb-1">  </b> <b class="mb-1"> <small style={{"color": "#409C09"}}> ♫ {song.lyrics.split(song.lyrics.split(' ')[song.number])[0]} </small> </b><small class="text-muted"> {song.lyrics.split(song.lyrics.split(' ')[song.number])[1]} ♫ </small></div> */}
            {/* <div style={{whiteSpace: 'pre-line'}}><b class="mb-1">  </b> <b class="mb-1"> <small style={{"color": "#409C09"}}> ♫ {song.lyrics.substring(0, song.lyrics.indexOf(song.lyrics.split(' ')[song.number]))} </small> </b><small class="text-muted"> {song.lyrics.substring(song.lyrics.indexOf(song.lyrics.split(' ')[song.number]))} ♫ </small></div> */}
            {/* <div><b class="mb-1">  </b> <small class="text-muted"> ♫ {song.songLink} ♫ </small></div> */}
            <div> <Badge bg="danger" pill style={{ margin: 15, padding:10}}> <img src="playbutton.png" alt=""></img> </Badge>
            {/* <button style={{ margin: 20, borderRadius:20}} ></button>  */}
            </div>
            <div> <i>Difficulty: </i> <small style={{}}>  </small>  <b class="mb-1"> Easy </b> </div>
            {/* <div> <button style={{marginTop: 10}} onClick={()=>sound.pause()}> pause </button> </div> */}
            {/* <Badge bg="danger" pill style={{ marginTop: 15, padding:10}}> 
            {(() => {
             songNumber = song.number 
        if ((song.number / song.lyrics.split(' ').length) <= 0.25) {
          difficulty = 'Hard 🥇'
          return (
            'Easy 🥇'
          )
        } else if (0.26 <= (song.number / song.lyrics.split(' ').length) <= 0.50) {
          difficulty = 'Medium 🥈'
          return (
           'Medium 🥈'
          )
        } else if (0.51 <= (song.number / song.lyrics.split(' ').length) <= 0.70) {
          difficulty = 'Easy 🥉'
          return (
           'Easy 🥉'
          )
        }else {
          difficulty = 'Very Easy 🪙'
          return (
            'V. Easy 🪙'
          )
        }
      })()}

        
      </Badge> */}
            </button>)
        
        )}

    

        <Modal show={show} onHide={handleClose} class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> 
        <Modal.Header closeButton>

          <Modal.Title>Difficulty: {diffi} <div> <button 
          onClick={()=> soundPlay(mySound)}>▶️</button> </div></Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container fluid style={{ margin: 25}} >
              <Row>
                <Col style={{whiteSpace: 'pre-line'}}> ♫ {original} ♫ </Col>
              </Row>
            </Container>

            <InputGroup size="sm" className="mb-3">
             <div class="container"> 
             <div class="row">
              <div class="col-sm"> 
                
              {inputFields.map((inputField, index) => (

                    <Form key={index}>
                        <Form.Control 
                        label="word" 
                        name="word" 
                        // value={original.split(' ')[(original.split(' ').length)/(lines_num)]} 
                        value={original.substring(0, original.indexOf(original.split(' ')[(original.split(' ').length)/(lines_num)]))}
                        // value={original.split(' ')[6]} 
                        // placeholder={song.lyrics} 
                        />
                         <div style={{whiteSpace: 'pre-line'}} className="text-center">
                         {inputField.word} 
                         <Badge bg="danger" pill style={{ margin: 10}}> {convertToSyllables(inputField.word)} </Badge>
                         </div> 
                    </Form>
                ))} 

               </div>
            </div> 
         </div> 
            </InputGroup>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="outline-danger" onClick={handleClose}>
            Save
          </Button>
          <Button variant="outline-danger" onClick={handleClose}>
            Publish
          </Button>
        </Modal.Footer>
      </Modal>
      
        </ul>

    );
};

export default Song;