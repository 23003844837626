import logo from './logo.svg';
import './App.css';
// import './assets/css/fonts.css';
// import './index.css'
import {Form, Carousel, Button, InputGroup, Alert, Card, Stack, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {authentication} from './firebase';
import { arrayUnion, connectFirestoreEmulator, QueryDocumentSnapshot, runTransaction } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css'
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Song from './Song';
import Tooltip from 'react-bootstrap/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import { lines, inputF } from './Song';
import mixpanel from 'mixpanel-browser';


const db = firebase.firestore();
var leadRef = db.collection('leads').doc()
// var compRef = db.collection('compositions').doc('firstSong')

var word = ""
var word_list = []
let sentenceSyllableSignatur = []
let sentenceSyllableSignature = []
let res = []
let data_out
let highest
var vote_num = ""
var totalVote = ""
var perc_vote_modal = ""
var count = ""
var original = ""
var next_ideas = ""
let next = -1
let button_text 
let buttonClicked = false
let idea = ''
var line_len
let liner = []
let interval1 = 5000
let interval2 = 5000
let interval3 = 5000
let interval4 = 5000
var top_text = ""
var result = ""

function App() {
  const [data,setData]=useState("click ⏭️ once you're ready");
  // const [orijo,setOrijo]=useState("");
  const [print,setPrint]=useState(false);
  const [songs, setResult] = useState([]);
  const [syn, setSyn] = useState(null);
  // const [idea, setIdea] = useState(null);
  const [show, setShow] = useState(true);
  const [showNext, setNextShow] = useState(true);
  const [showMini, setShowMini] = useState("click ⏭️ once you're ready");
  const [counter, setCounter] = React.useState(600);
  const [text, setText] = useState("");
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [index3, setIndex3] = useState(0);
  const [index4, setIndex4] = useState(0);
  const [email, setEmail]=useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleShow = (perc_vote) => {
   perc_vote_modal = perc_vote
   if (perc_vote_modal < 15){
    result = "Sorry only " + perc_vote_modal + "% of listeners enjoyed your taste in Music. It's sadly is not a hit."
    setShowModal(true)
   }else{
    result = "Congratulations! " + perc_vote_modal + "% of listeners loved your taste in Music. You've got a hit! $$$"
    setShowModal(true)
   }
   
  }

  // get game to load
//  useEffect(() => {
//   // delay(5000)
//   getIdea()
//   },[])
  // var data_len = data.length
  // var line_len = data_len/4
  // const chunks = data.match(/.{1,25}/g);   
  // modal
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
//  data_out = data
  const songsRef = db.collection("songs");
  const ideaRef = db.collection("ideas");
  mixpanel.init("05cba2b0a08ef860de0629b3b1a3e5bf", {
  });
  
  // timer
  // React.useEffect(() => {
  //   counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // }, [counter]);


  const handleSelect1 = (selectedIndex, e) => {
    setIndex1(selectedIndex);
    // console.log('index: ', selectedIndex)
  };

  const handleSelect2 = (selectedIndex, e) => {
    setIndex2(selectedIndex);
    // console.log('index: ', selectedIndex)
  };

  const handleSelect3 = (selectedIndex, e) => {
    setIndex3(selectedIndex);
    // console.log('index: ', selectedIndex)
  };

  const handleSelect4 = (selectedIndex, e) => {
    setIndex4(selectedIndex);
    // console.log('index: ', selectedIndex)
  };

  function breakSentenceIntoWords(){
    word = word.replaceAll('-',' ')  
    // console.log('word in breaker: ', word)
    word_list = word.split(" ")
    // console.log(word_list)
    return word_list
  }


  useEffect(() => {
   breakText();
    },[lines]);

  // }, [lines]);
  // const getSyn = async () => {
  //   const { Configuration, OpenAIApi } = require("openai")
  //   const configuration = new Configuration({
  //     apiKey: "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"
  //   });
  //   const openai = new OpenAIApi(configuration);
  //   const response = await openai.createCompletion({
  //     model: "text-davinci-003",
  //     prompt: "replace the word 'individual' with a synonym in this sentence: Risk analysis and risk management is a process that allows individual risk events and overall risk to be understood and managed proactively, optimising success by minimising threats and maximising opportunities and outcomes",
  //     temperature: 0.3,
  //     top_p:1.0,
  //     max_tokens: 150,
  //   });
  //   console.log(response.choices.text)
  // }


  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + String('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')
    },
    body: JSON.stringify({
      'prompt': "simplify this sentence to a 10 year old child : " + data,
      'temperature': 0.3,
      'max_tokens': 150,
      'top_p': 1,
      'frequency_penalty': 0,
      'presence_penalty': 0.0,
      'stop': ["\"\"\""],
    })
  };

  function getSyn(){
    // console.log('data')
    // console.log(data)
    // data_out = null
    // setData(null)
    // console.log('data')
    // console.log(data)
    fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', requestOptions)
    .then(response => response.json())
    .then(datai => {
      // # Do something with data
      // console.log(datai.choices[0].text)
      // setSyn(datai.choices[0].text)
      idea = datai.choices[0].text
      setData(idea)
      search()
      // data_out = datai.choices[0].text
    //   console.log('data')
    // console.log(data)
  }).catch(err => {
    console.log("Ran out of tokens for today! Try tomorrow!");
  });
  }
 


  function convertToSyllables(){
    word_list = breakSentenceIntoWords()
    for (let i = 0; i < word_list.length; i++){
      var oneword = word_list[i].toLowerCase(); 
      oneword = oneword.replace(',','')                                 
      var oneword_1 = oneword.replace(/(?:[^laeiouy]|ed|[^laeiouy]e)$/, '');   
      var oneword_2 = oneword_1.replace(/^y/, '');                                  
      var syl = oneword_2.match(/[aeiouy]{1,2}/g);
      if (oneword =='created') {
        syl = ['n', 't', 'k']
      }
      if (oneword =='destroyed') {
        syl = ['n', 't']
      }
      if(syl == null){
        syl = ['n']
      }
      // console.log("here: "+ syl);
      if(i==0)
      {
        // create space between the second sign and the last comma like in db
      sentenceSyllableSignatur.push(syl.length)
      // console.log('syl1: ', syl)
      }else{
        sentenceSyllableSignatur.push(' '+syl.length)
      // console.log(syl)
      }
    }
    return sentenceSyllableSignatur;
  }

  function getIdea(){
    setShow(false)
    setShowMini(" Follow the Rythm of the Song below to arrive at your New Song")
    top_text = 'Slide left and right using the arrows till you get the perfect mix for your hit Song!'
    // todo:if ideas is empty do database
    // else take from variable
    mixpanel.track('next button', {
      time: new Date().toLocaleString()
    })
    const queryIdea = ideaRef.where("tag", "array-contains", 'game');
      queryIdea.get().then((snapshot) => {
            if (snapshot.empty) {
              // console.log('not found')
              
            }
            else {
             const idea_result = snapshot.docs.map((d) => ({'idea': d.data().idea, 'tag': d.data().tag, 'lines':d.data().lines}))
              // get an array of all ideas with physics in the tag
              // console.log(idea_result)
              // pick one
              var ideas = idea_result
              next = next + 1
              next_ideas = ideas[next]
              // console.log(next)
              // console.log(next_ideas)
              // setIdea(next_ideas['idea'])
              idea = next_ideas['idea']
              liner = next_ideas['lines']
              console.log('liner: ', next_ideas['lines'])
              setData(idea)
              // set it to a variable
              search()
              // return;
            }
          });

  }

  // timer show
  if (buttonClicked == true){
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }
  // else{
  //   button_text = ''
  // }
  


 function search (){
    setResult([])
    setNextShow(false)
    word_list = []
    sentenceSyllableSignatur = []
    sentenceSyllableSignature = []
    res = []
    buttonClicked = true
    // songs = []
  // console.log('result')
    var found = false
    // console.log('syn')
    // console.log('songs: ', songs)
    // console.log('data: ', data)
    // console.log('idea: ', idea)

    if(idea != ''){
      word = idea
    }else{
      word = data
    }
    // console.log('word')
    // console.log(word)
    
    sentenceSyllableSignatur = convertToSyllables()
    // console.log(sentenceSyllableSignatur)
    // sentenceSyllableSignature = Array.from(sentenceSyllableSignature);
    // console.log(sentenceSyllableSignature)
    // sentenceSyllableSignature = "[" + sentenceSyllableSignature + "]"
    // console.log("syl2: " + sentenceSyllableSignature)
    // highest = false
    for (let i=sentenceSyllableSignatur.length; i >=1; i--){
      sentenceSyllableSignatur = sentenceSyllableSignatur.slice(0,i+1);
      sentenceSyllableSignature = "[" + sentenceSyllableSignatur + "]"
      //  console.log(sentenceSyllableSignature)

      switch(i) {
        case 20:
          // code block
          const query20 = songsRef.where("codedLyrics20", "==", sentenceSyllableSignature.toString());
          query20.get().then((snapshot20) => {
            if (snapshot20.empty) {
              // console.log("syl20: " + sentenceSyllableSignature.toString())
              // console.log('not found')
              sentenceSyllableSignature = []
              // console.log("e enter 20 empty")
              
            }
            else {
             const song_result20 = snapshot20.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 20}))
              // set this array in your state
              // for(var i=0; i<=song_result.length; i++){
              found = true
              res = res.concat(song_result20)
                // setResult(song_result20)
              // }
              
              // console.log(typeof songs)
              // console.log(song_result20)
              // sentenceSyllableSignature = []
              // console.log("e enter 20 full")
              // console.log('songs')
              // console.log(songs)
              // console.log('res')
              // console.log(res)
              highest = true
              // console.log('highest3: ',highest)
              return;
            }
        });
          break;
        case 19:
          // code block
          // find next
          // console.log(songs)
          // console.log("e enter 19 normal")
          const query19 = songsRef.where("codedLyrics19", "==", sentenceSyllableSignature.toString());
          query19.get().then((snapshot19) => {
            if (snapshot19.empty) {
              // console.log("syl19: " + sentenceSyllableSignature.toString())
              // console.log('not found')
              sentenceSyllableSignature = []
              // console.log("e enter 19 empty")
            }
            else {
             const song_result19 = snapshot19.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 19}))
              // set this array in your state
              // for(var i=0; i<=song_result.length; i++){
                found = true
                res = res.concat(song_result19)
                // setResult(song_result19)
              // }
              
              // console.log(typeof songs)
              console.log(song_result19)
              sentenceSyllableSignature = []
              console.log("e enter 19 full")
              console.log('songs')
              console.log(songs)
              console.log('res')
              console.log(res)
              highest = true
              console.log('highest3: ',highest)
              return;
            }
  
            console.log("e enter 19 normal")
            // console.log(songs)
          });
          break;
        // default:
          // code block

          case 18:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 18 normal")
            const query18 = songsRef.where("codedLyrics18", "==", sentenceSyllableSignature.toString());
            query18.get().then((snapshot18) => {
              if (snapshot18.empty) {
                console.log("syl18: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 18 empty")
              }
              else {
               const song_result18 = snapshot18.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 18}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result18)
                  // setResult(song_result18)
                // }
                
                // console.log(typeof songs)
                console.log(song_result18)
                sentenceSyllableSignature = []
                console.log("e enter 18 full")
                console.log('songs')
                console.log(songs)
                console.log('res')
                console.log(res)
                highest = true
                return;
              }
    
              console.log("e enter 18 normal")
              // console.log(songs)
            });
            break;

            case 17:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 17 normal")
            const query17 = songsRef.where("codedLyrics17", "==", sentenceSyllableSignature.toString());
            query17.get().then((snapshot17) => {
              if (snapshot17.empty) {
                console.log("syl17: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 17 empty")
              }
              else {
               const song_result17 = snapshot17.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 17}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result17)
                  // setResult(song_result17)
                // }
                
                // console.log(typeof songs)
                console.log(song_result17)
                sentenceSyllableSignature = []
                console.log("e enter 17 full")
                console.log('songs')
                console.log(songs)
                console.log('res')
                console.log(res)
                highest = true
                return;
              }
    
              console.log("e enter 17 normal")
              // console.log(songs)
            });
            break;

            case 16:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 16 normal")
            const query16 = songsRef.where("codedLyrics16", "==", sentenceSyllableSignature.toString());
            query16.get().then((snapshot16) => {
              if (snapshot16.empty) {
                console.log("syl16: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 16 empty")
              }
              else {
               const song_result16 = snapshot16.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 16}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result16)
                  // setResult(song_result16)
                // }
                
                // console.log(typeof songs)
                console.log(song_result16)
                sentenceSyllableSignature = []
                console.log("e enter 16 full")
                console.log('songs')
                console.log(songs)
                console.log('res')
                console.log(res)
                highest = true
                return;
              }
    
              console.log("e enter 16 normal")
              // console.log(songs)
            });
            break;


            case 15:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 15 normal")
            const query15 = songsRef.where("codedLyrics15", "==", sentenceSyllableSignature.toString());
            query15.get().then((snapshot15) => {
              if (snapshot15.empty) {
                console.log("syl15: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 15 empty")
              }
              else {
               const song_result15 = snapshot15.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 15}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result15)
                  // setResult(song_result15)
                // }
                
                // console.log(typeof songs)
                console.log(song_result15)
                sentenceSyllableSignature = []
                console.log("e enter 15 full")
                console.log('songs')
                console.log(songs)
                console.log('res')
                console.log(res)
                highest = true
                return;
              }
    
              console.log("e enter 15 normal")
              // console.log(songs)
            });
            break;


            case 14:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 14 normal")
            const query14 = songsRef.where("codedLyrics14", "==", sentenceSyllableSignature.toString());
            query14.get().then((snapshot14) => {
              if (snapshot14.empty) {
                console.log("syl14: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 14 empty")
              }
              else {
               const song_result14 = snapshot14.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 14}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result14)
                  // setResult(song_result14)
                // }
                
                // console.log(typeof songs)
                console.log(song_result14)
                sentenceSyllableSignature = []
                console.log("e enter 14 full")
                console.log('songs')
                console.log(songs)
                console.log('res')
                console.log(res)
                highest = true
                return;
              }
    
              console.log("e enter 14 normal")
              // console.log(songs)
            });
            break;


            case 13:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 13 normal")
            const query13 = songsRef.where("codedLyrics13", "==", sentenceSyllableSignature.toString());
            query13.get().then((snapshot13) => {
              if (snapshot13.empty) {
                console.log("syl13: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 13 empty")
              }
              else {
               const song_result13 = snapshot13.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 13}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result13)
                  // setResult(song_result13)
                // }
                
                // console.log(typeof songs)
                console.log(song_result13)
                sentenceSyllableSignature = []
                console.log("e enter 13 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 13 normal")
              // console.log(songs)
            });
            break;


            case 12:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 12 normal")
            const query12 = songsRef.where("codedLyrics12", "==", sentenceSyllableSignature.toString());
            query12.get().then((snapshot12) => {
              if (snapshot12.empty) {
                console.log("syl12: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 12 empty")
              }
              else {
               const song_result12 = snapshot12.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 12}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result12)
                  // setResult(song_result12)
                // }
                
                // console.log(typeof songs)
                console.log(song_result12)
                sentenceSyllableSignature = []
                console.log("e enter 12 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 12 normal")
              // console.log(songs)
            });
            break;


            case 11:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 11 normal")
            const query11 = songsRef.where("codedLyrics11", "==", sentenceSyllableSignature.toString());
            query11.get().then((snapshot11) => {
              if (snapshot11.empty) {
                console.log("syl11: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 11 empty")
              }
              else {
               const song_result11 = snapshot11.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 11}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result11)
                  // setResult(song_result11)
                // }
                
                // console.log(typeof songs)
                console.log(song_result11)
                sentenceSyllableSignature = []
                console.log("e enter 11 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 11 normal")
              // console.log(songs)
            });
            break;


            case 10:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 10 normal")
            const query10 = songsRef.where("codedLyrics10", "==", sentenceSyllableSignature.toString());
            query10.get().then((snapshot10) => {
              if (snapshot10.empty) {
                console.log("syl10: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 10 empty")
              }
              else {
               const song_result10 = snapshot10.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 10}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result10)
                  // setResult(song_result10)
                // }
                
                // console.log(typeof songs)
                console.log(song_result10)
                sentenceSyllableSignature = []
                console.log("e enter 10 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 10 normal")
              // console.log(songs)
            });
            break;


            case 9:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 9 normal")
            const query9 = songsRef.where("codedLyrics9", "==", sentenceSyllableSignature.toString());
            query9.get().then((snapshot9) => {
              if (snapshot9.empty) {
                console.log("syl9: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 9 empty")
              }
              else {
               const song_result9 = snapshot9.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 9}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result9)
                  // setResult(song_result9)
                // }
                
                // console.log(typeof songs)
                console.log(song_result9)
                sentenceSyllableSignature = []
                console.log("e enter 9 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 9 normal")
              // console.log(songs)
            });
            break;


            case 8:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 8 normal")
            const query8 = songsRef.where("codedLyrics8", "==", sentenceSyllableSignature.toString());
            query8.get().then((snapshot8) => {
              if (snapshot8.empty) {
                console.log("syl8: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 8 empty")
              }
              else {
               const song_result8 = snapshot8.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 8}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  // setResult(song_result8)
                  res = res.concat(song_result8)
                // }
                
                // console.log(typeof songs)
                console.log(song_result8)
                sentenceSyllableSignature = []
                console.log("e enter 8 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 8 normal")
              // console.log(songs)
            });
            break;


            case 7:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 7 normal")
            const query7 = songsRef.where("codedLyrics7", "==", sentenceSyllableSignature.toString());
            query7.get().then((snapshot7) => {
              if (snapshot7.empty) {
                console.log("syl7: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 7 empty")
              }
              else {
               const song_result7 = snapshot7.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 7}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result7)
                  // setResult(song_result7)
                // }
                
                // console.log(typeof songs)
                console.log(song_result7)
                sentenceSyllableSignature = []
                console.log("e enter 7 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 7 normal")
              // console.log(songs)
            });
            break;


            case 6:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 6 normal")
            const query6 = songsRef.where("codedLyrics6", "==", sentenceSyllableSignature.toString());
            query6.get().then((snapshot6) => {
              if (snapshot6.empty) {
                console.log("syl6: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 6 empty")
              }
              else {
               const song_result6 = snapshot6.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 6}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result6)
                  // setResult(song_result6)
                // }
                
                // console.log(typeof songs)
                console.log(song_result6)
                sentenceSyllableSignature = []
                console.log("e enter 6 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 6 normal")
              // console.log(songs)
            });
            break;


            case 5:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 5 normal")
            const query5 = songsRef.where("codedLyrics5", "==", sentenceSyllableSignature.toString());
            query5.get().then((snapshot5) => {
              if (snapshot5.empty) {
                console.log("syl5: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 5 empty")
              }
              else {
               const song_result5 = snapshot5.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 5}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result5)
                  // setResult(song_result5)
                // }
                
                // console.log(typeof songs)
                console.log(song_result5)
                sentenceSyllableSignature = []
                console.log("e enter 5 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 5 normal")
              // console.log(songs)
            });
            break;


            case 4:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 4 normal")
            const query4 = songsRef.where("codedLyrics4", "==", sentenceSyllableSignature.toString());
            query4.get().then((snapshot4) => {
              if (snapshot4.empty) {
                console.log("syl4: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 4 empty")
              }
              else {
               const song_result4 = snapshot4.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 4}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  res = res.concat(song_result4)
                  // setResult(song_result4)
                // }
                
                // console.log(typeof songs)
                console.log(song_result4)
                sentenceSyllableSignature = []
                console.log("e enter 4 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 4 normal")
              // console.log(songs)
            });
            break;

            case 3:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 3 normal")
            const query3 = songsRef.where("codedLyrics3", "==", sentenceSyllableSignature.toString());
            query3.get().then((snapshot3) => {
              if (snapshot3.empty) {
                console.log("syl3: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 3 empty")
              }
              else {
               const song_result3 = snapshot3.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 3}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  // setResult(song_result3)
                  res = res.concat(song_result3)
                // }
                
                // console.log(typeof songs)
                console.log(song_result3)
                sentenceSyllableSignature = []
                console.log("e enter 3 full")
                console.log('songs')
                console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 3 normal")
              // console.log(songs)
            });
            break;

            case 2:
            // code block
            // find next
            // console.log(songs)
            console.log("e enter 2 normal")
            const query2 = songsRef.where("codedLyrics2", "==", sentenceSyllableSignature.toString());
            query2.get().then((snapshot2) => {
              if (snapshot2.empty) {
                console.log("syl2: " + sentenceSyllableSignature.toString())
                console.log('not found')
                sentenceSyllableSignature = []
                console.log("e enter 2 empty")
              }
              else {
               const song_result2 = snapshot2.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 2}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  // setResult(song_result2)
                  res = res.concat(song_result2)
                // }
                
                // console.log(typeof songs)
                // console.log(song_result2)
                sentenceSyllableSignature = []
                // console.log("e enter 2 full")
                // console.log('songs')
                // console.log(songs)
                highest = true
                return;
              }
    
              console.log("e enter 2 normal")
              // console.log(songs)
            });
            break;

            case 1:
            // code block
            // find next
            // console.log(songs)
            // console.log("e enter 1 normal")
            const query1 = songsRef.where("codedLyrics1", "==", sentenceSyllableSignature.toString());
            query1.get().then((snapshot1) => {
              if (snapshot1.empty) {
                // console.log("syl1: " + sentenceSyllableSignature.toString())
                // console.log('not found')
                sentenceSyllableSignature = []
                // console.log("e enter 1 empty")
              }
              else {
               const song_result1 = snapshot1.docs.map((d) => ({'songTitle': d.data().songTitle, 'lyrics': d.data().lyrics, 'artistName': d.data().artistName, 'songLink': d.data().songLink, 'number': 1}))
                // set this array in your state
                // for(var i=0; i<=song_result.length; i++){
                  found = true
                  // setResult(song_result1)
                  res = res.concat(song_result1)
                // }
                
                // console.log(typeof songs)
                // console.log(song_result1)
                sentenceSyllableSignature = []
                // console.log("e enter 1 full")
                // console.log('songs')
                // console.log(songs)
              }
    
              // console.log("e enter 1 normal")
              // console.log('songs')
              // console.log('finalres: ', res)

            // delete repeated items from final result
              for (let i = res.length - 1; i >= 0; i--) {
                for (let j = 0; j < i; j++) {
                  if (res[i].lyrics === res[j].lyrics) {
                    res.splice(i, 1);
                    break;
                  }
                  if(res[i].number <= 2){
                    res.splice(i, 1);
                    break;
                  }
                }
              }
              // console.log('finalfinalres: ', res)
              setResult(res)
              if(found == false) {
                // console.log("Sorry no result found")
                // setResult('Sorry no result found')
            }
            
            });
            break;
      
    }

    }
    
  }


  // get data from input
  function getData(val)
  {
    // if(syn != null){
    //   setData(syn)
    // }else{
      setData(val.target.value)
      setPrint(false)
      // console.warn(val.target.value.split('\n'))
      // setLineCount(val.target.value.split('\n').length);
    // }
    
  }

  function breakText() {
    var dataa = data.replace(/(\r\n|\n|\r)/gm, "");
    var data_len = dataa.split(' ').length
    // console.log('words: ', data_len)
    line_len = data_len/(lines+1)
    line_len = Math.round(line_len)
    // console.log('line_len: ', line_len)

    let brokenText = " ";
    for (let i = 0; i < data_len; i++) {
      if (i % line_len === 0 && i !== 0) {
        brokenText += "\n";
      }
      brokenText += dataa.split(' ')[i] + " ";
    }
    setData(brokenText);
  }

  function pause1(){
    interval1 = null
  }

  function pause2(){
    interval2 = null
  }

  function pause3(){
    interval3 = null
  }

  function pause4(){
    interval4 = null
  }

  function uploadResult(){

    var songSign ="" + index1 + index2 +index3 +index4
    var compRef = db.collection('compositions').doc(songSign)
    var totCompRef = db.collection('compositions').doc('totComp')

    mixpanel.track('submit button', {
      time: new Date().toLocaleString(),
      compoSign: songSign
    })

      var docData = {
        compoSign: songSign,
        vote: 1
      }


      totCompRef
      .get()
      .then((snapshoty) => {
        if (snapshoty.empty) {
          // alert('Sorry no courses available at the moment')
        }else{
          // console.log('virality:', vote_num/snapshot.docs[0].data().vote)
          totalVote = snapshoty.data().totalVote
        }
      });

         // check if user already exist before creating profile
         var querydQ = db.collection("compositions").where("compoSign", "==", songSign)
         querydQ.get().then((snapshot) => {
               if(!snapshot.empty){
                  compRef.update({
                    //subtract the amount from expecting.
                        vote: firebase.firestore.FieldValue.increment(1),
                      });
                      
                      totCompRef.update({
                        totalVote: firebase.firestore.FieldValue.increment(1)
                        });

                  // console.log('snapshot:', snapshot.docs[0].data().vote)
                  vote_num = snapshot.docs[0].data().vote
                  var perc_vote = Math.ceil((vote_num/totalVote) * 100)
                  handleShow(perc_vote)
                  // alert(perc_vote + '% of kids prefered your song')

              }else{
                  // if empty create new doc
                  compRef.set(docData).then(() => {
                    // console.log(docData);
                  });

                  totCompRef.update({
                    totalVote: firebase.firestore.FieldValue.increment(1)
                    }); 

                    // vote_num = snapshot.docs[0].data().vote
                    var perc_vote = Math.ceil((1/totalVote) * 100)
                    // alert(perc_vote + '% of Students prefered your Song')
                    handleShow(perc_vote)
                    // console.log('snapshot:', snapshot.docs[0].data().vote)
                // return;


              }
            });
  }

  function sos(){
    mixpanel.track('share', {
      time: new Date().toLocaleString()
    })
  }

  function uploadEmail(){

        if (email==null){
          alert('Please, input email')
          return
      }

      mixpanel.track('upload email', {
        time: new Date().toLocaleString()
  
      })

          var docData = {
            email: email
          }

          leadRef.set(docData).then(() => {
            console.log(docData);
        });

    alert("Great! Thank you for signing up")
        setShowModal(false)
   
  }

  function getEmail(val)
  {
    setEmail(val.target.value)
  }

  return (
    <div className="App">
    <div className="container">
      <nav class="navbar navbar-expand navbar-light">
        <a href="#" class="navbar-brand">🎵 ClassChoir </a>
        <button
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
            {/* <li>
              <a class="nav-link" onClick={() => setShow(true)} href="#"> How❓ </a>
            </li> */}
            {/* <li>
              <a class="nav-link" href="#">
              {!show && <Button style={{ height: 30}} variant="outline-danger" onClick={() => setShow(true)}> ?</Button>}
              </a>
            </li> */}
            {/* <li>
              <a class="nav-link" href="#"><b>00:{counter}</b> Secs</a>
            </li> */}
          </ul>
        </div>
        {/* <span class="navbar-text">
        <a class="nav-link" href="#"><b>000:000:{counter}</b> Secs</a>
        </span>  */}
        <span class="navbar-text">
            <a href="whatsapp://send?text= Kiki, Sophie and May are in special class, help them convert their School Work to Music! https://ClassChoir.com. 🥺🙏" data-action="share/whatsapp/share"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-free-vector-download-7.png" width="25" alt="Logo Whatsapp Png Free Vector Download" onClick={sos}/> </a>
        </span>
        {/* <span class="navbar-text">
          <a class="nav-link" onClick={() => setShow(true)} href="#"> How❓ </a>
        </span> */}
      </nav>
    </div>
        {/* <h5 style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginLeft:10, marginTop:20, height:'5vh'}}>
          Help Kiki and Classmates convert their School Work to Music!
        </h5> */}

      {!show &&
        <Form show={show}>
      {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ margin: 5, marginTop: 10, whiteSpace: 'pre'}}>
        <InputGroup hasValidation>
            <Form.Control
              as="textarea"
              rows={5}
              type="text"
              placeholder="click ⏭️⏭️⏭️ once you're ready"
              aria-describedby="inputGroupPrepend"
              onChange={getData}
              value={data}
              required
            />
          </InputGroup>
      </Form.Group> */}

      {/* <Button style={{ margin: 20, borderRadius:3}} variant="primary" onClick={getSyn}>
        Rephrase
      </Button> */}
      {/* <Button style={{ margin: 20, borderRadius:3, width: 150, height: 60}} variant="outline-primary" onClick={search}>
      ♫ Remix
      </Button> */}
      {/* <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">give me ideas</Tooltip>}
    > */}
      {/* {({ ref, ...triggerHandler }) => (
      )} */}
    {/* </OverlayTrigger> */}

    {/* use this for schrolling */}
     {/* <div style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'scroll'
          }}> */}
                {/* </div> */}


    <Card className="text-center" bg='light'>
    <Card.Header><h6> {showMini} </h6></Card.Header>
      {/* <Card.Header><h6> Help Kiki and her classmates find the rythm in their School Work!</h6></Card.Header> */}
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        <Card.Text>
        {!show &&
   
      <React.Fragment>
        {
          <Song songs={songs} original={data}/>
        }
      </React.Fragment>
      }
        {/* {inputF.map((inputField, index) => (

          <Form key={index}>
              <Form.Control 
              label="word" 
              name="word" 
              // value={original.split(' ')[(original.split(' ').length)/(lines_num)]} 
              value={data.substring(0, data.indexOf(data.split(' ')[(data.split(' ').length)/(line_len)]))}
              // value={original.split(' ')[6]} 
              // placeholder={song.lyrics} 
              />
              <div style={{whiteSpace: 'pre-line'}} className="text-center">
              {inputField.word} 
              <Badge bg="danger" pill style={{ margin: 10}}> {convertToSyllables(inputField.word)} </Badge>
              </div> 
          </Form>
        ))}  */}
        {/* <InputGroup hasValidation>
            <Form.Control
              as="textarea"
              rows={5}
              type="text"
              placeholder="click ⏭️ once you're ready"
              aria-describedby="inputGroupPrepend"
              onChange={getData}
              value={data}
              required
            />
          </InputGroup> */}
          

          {/* <div>
          <pre>{text}</pre>
        </div> */}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="text-muted">{top_text}</Card.Footer>
    </Card>

    <Card className="text-center">
      <Card.Body>
        <InputGroup >
            <Stack gap={2}>
            
            {liner.map((line, id) => 
              <Carousel variant="light" interval={null} onSlid={handleSelect1}>
                  <Carousel.Item>
                    <div  onClick={pause1} style={{color: 'white'}} className="bg-danger border">{line.line_one}</div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div  onClick={pause1} style={{color: 'white'}} className="bg-danger border">{line.line_two}</div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div  onClick={pause1} style={{color: 'white'}} className="bg-danger border">{line.line_three}</div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div  onClick={pause1} style={{color: 'white'}} className="bg-danger border">{line.line_four}</div>
                  </Carousel.Item>
              </Carousel>
            )}

            </Stack> 
          </InputGroup>
         
    
      </Card.Body>
      {/* <Card.Footer style={{ marginTop: 20, padding:10}} variant="outline-danger" onClick={uploadResult}>2 days ago
      Publish My Song ✔️
      </Card.Footer> */}
      {/* <Card.Footer className="text-muted">2 days ago
      
      </Card.Footer> */}
    </Card>

  

    {/* <Button style={{ margin: 10, borderRadius:3, height: 45, }} variant="outline-danger" onClick={getSyn}>
    ♻️
    </Button>  */}
    {/* <Button style={{ margin: 10, borderRadius:3, height: 50}} variant="outline-danger" onClick={search}>
    🔍
    </Button> */}
    {/* <Button href="whatsapp://send?text= Help me convert this School Work to a Song so Kids can sing it in class! https://ClassChoir.com. 🥺🙏" data-action="share/whatsapp/share" style={{ margin: 10, borderRadius:3, height: 45}} variant="outline-danger" onClick={'breakText'}>
    ✔️
    </Button>  */}
    {/* {showNext &&
      <Button style={{ marginTop: 50, borderRadius:20, height: 45, width: 150}} variant="outline-danger" onClick={getIdea}>
       ⏭️
    </Button>
    } */}

    {!showNext &&
    <Button style={{ marginTop: 50, borderRadius:20, height: 45}} variant="outline-danger" onClick={uploadResult}>
    Publish My Song ✔️
    </Button> 
    }

    {/* <Button class="bi bi-whatsapp" href="whatsapp://send?text= Help me convert this School Work to a Song so Kids can sing it in class! https://ClassChoir.com. 🥺🙏" data-action="share/whatsapp/share" style={{ margin: 10, borderRadius:3, height: 45}} variant="outline-danger" onClick={sos}>
    🆘
    </Button>  */}
       
    {/* { showMini &&
      <div class="container p-3 my-3 bg-primary text-white">
          <Row>
            <Col>
              Select a Song that best fits the school work.
            </Col>
          </Row>
      </div>} */}
    </Form>
    

    }


      <Alert show={show} variant="success">
        {/* <Alert.Heading>Goal: </Alert.Heading>
        <p>
          Help Kids with special needs convert school work to Music!
        </p> */}
        <Alert.Heading></Alert.Heading>
        <Alert.Heading> Story</Alert.Heading> 
        <p>
        A New Music Genre called "Geek Music" is loved by Students World Wide 🌍. 
        </p>
        <p>
        Have you got great Taste for Music to be the Biggest Geek Artist? 
        </p>
       
        {/* <p> */}
        {/* Slide the 4 Red bars to choose the Lyrics for your New Song. */}
        {/* Match the rythm of the School Work with the Rythm of the Popular Song to create your New Song! */}
        {/* </p> */}
        {/* <p> */}
        {/* Click ✔️ to submit your New song. */}
        {/* Match the rythm of the School Work with the Rythm of the Popular Song to create your New Song! */}
        {/* </p> */}
        {/* <Alert.Heading style={{ marginTop: 40}}>Hint</Alert.Heading> 
        <p>
         Follow the Rythm of the old song to arrive at your New Hit Song.
        </p> */}
        <Alert.Heading style={{ marginTop: 40}}>How are Results Calculated?</Alert.Heading> 
        <p>
         Your song success is estimated by the amount of players who chose the exact lyrics combination as you.
        </p>
        {/* <p>
          Feel free to delete or add new sentences
        </p>   */}
        {/* <Alert.Heading>For example:</Alert.Heading>
        <p>
          "Probability" has 5 syllables (rythms), to match the lyrics
        </p>
        <p>
           ♫"is it too late now to say sorry"♫ 
        </p>
        <p>
          You can break it into
        </p>
        <p>
        ♫"Pro ba bi li ty is a number"♫.
        </p> */}
        <hr />

        <div className="d-flex justify-content-end">
          <Button style={{ margin: 5, borderRadius:25, width: 120, height: 50}} onClick={getIdea} variant="outline-danger">
            Play
          </Button>
        </div>
      </Alert>


      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{result} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Want More Song from ClassChoir?</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={getEmail}
                autoFocus
              />
            </Form.Group>
        
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ borderRadius:20}} variant="danger" onClick={uploadEmail}>
            Sign Me Up!
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}

export default App;
